import { ArchiveIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import * as React from 'react';
type TableColumn<Entry> = {
  title: string;
  field: keyof Entry;
  Cell?({ entry }: { entry: Entry }): React.ReactElement;
};

export type TableProps<Entry> = {
  data: Entry[];
  columns: TableColumn<Entry>[];
  overflow?: boolean;
};

export const Table = <Entry extends { id: string }>({
  data,
  columns,
  overflow = false,
}: TableProps<Entry>) => {
  if (!data?.length) {
    return (
      <div className="bg-white text-neutral-500 h-80 flex justify-center items-center flex-col">
        <ArchiveIcon className="h-16 w-16" />
        <h4>No Entries Found</h4>
      </div>
    );
  }
  return (
    <div className="flex flex-col">
      {/* removed overflow-x-auto here to allow dropdowns to work */}
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            className={clsx(
              'shadow border-b border-neutral-300 sm:rounded-lg',
              overflow ? '' : 'overflow-hidden'
            )}
          >
            <table className="min-w-full divide-y divide-neutral-300">
              <thead className="bg-neutral-300">
                <tr>
                  {columns.map((column, index) => (
                    <th
                      key={column.title + index}
                      scope="col"
                      className={clsx(
                        'px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider',
                        index === columns.length - 1 && 'sm:rounded-tr-lg',
                        index === 0 && 'sm:rounded-tl-lg'
                      )}
                    >
                      {column.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((entry, entryIndex) => (
                  <tr
                    key={entry?.id || entryIndex}
                    className={entryIndex % 2 === 0 ? 'bg-white' : 'bg-neutral-300'}
                  >
                    {columns.map(({ Cell, field, title }, columnIndex) => (
                      <td
                        key={title + columnIndex}
                        className={clsx(
                          'px-6 py-4 whitespace-nowrap text-sm font-medium text-neutral-900',
                          entryIndex === data.length - 1 &&
                            columnIndex === columns.length - 1 &&
                            'sm:rounded-br-lg',
                          entryIndex === data.length - 1 && columnIndex === 0 && 'sm:rounded-bl-lg'
                        )}
                      >
                        {Cell ? <Cell entry={entry} /> : entry[field]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
