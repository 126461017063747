import clsx from 'clsx';
import * as React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type Option = {
  label: React.ReactNode;
  value: string | number | string[];
};

type SelectFieldProps = FieldWrapperPassThroughProps & {
  options: Option[];
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  registration: Partial<UseFormRegisterReturn>;
};

export const SelectField = (props: SelectFieldProps) => {
  const { label, options, error, className, defaultValue, registration, placeholder, disabled } =
    props;
  return (
    <FieldWrapper label={label} error={error} className="relative">
      <select
        placeholder={placeholder}
        disabled={disabled}
        name="location"
        className={clsx(
          'form-select cursor-pointer appearance-none block w-full pl-3 pr-10 py-2 text-base bg-neutral-000 bg-clip-padding bg-no-repeat border border-solid border-neutral-400 rounded-md shadow-sm transition ease-in-out m-0 focus:text-neutral-700 focus:bg-neutral-000 focus:border-royal-blue-500 focus:outline-none',
          className
        )}
        defaultValue={defaultValue}
        {...registration}
      >
        {options.map(({ label, value }) => (
          <option key={label?.toString()} value={value}>
            {label}
          </option>
        ))}
      </select>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 absolute right-2 bottom-2 fill-neutral-400 text-neutral-400 pointer-events-none"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
      </svg>
    </FieldWrapper>
  );
};
