import * as React from 'react';

// import { Tag } from '@/features/tags';
import { User } from '@/features/users';

import { useAuth } from './auth';

export enum USERTYPES_ENUM {
  ADMIN = 'ADMIN',
  ISSUER = 'ISSUER',
  USER = 'USER',
}

type UserTypes = keyof typeof USERTYPES_ENUM;

export const POLICIES = {
  'tag:configure': (user: User) => {
    if ((user.userType === 'ADMIN' || user.userType === 'ISSUER') && !!user.entity) {
      return true;
    }

    return false;
  },
};

export const useAuthorization = () => {
  const { user } = useAuth();

  const checkAccess = React.useCallback(
    ({ allowedUserTypes }: { allowedUserTypes: UserTypes[] }) => {
      if (!user) return false;
      if (allowedUserTypes && allowedUserTypes.length > 0) {
        return allowedUserTypes?.includes(user.user_type);
      }

      return true;
    },
    [user]
  );

  return { checkAccess, userType: user?.user_type };
};

type AuthorizationProps = {
  forbiddenFallback?: React.ReactNode;
  children: React.ReactNode;
} & (
  | {
      allowedUserTypes: UserTypes[];
      policyCheck?: never;
    }
  | {
      allowedUserTypes?: never;
      policyCheck: boolean;
    }
);

export const Authorization = ({
  policyCheck,
  allowedUserTypes,
  forbiddenFallback = null,
  children,
}: AuthorizationProps) => {
  const { checkAccess } = useAuthorization();

  let canAccess = false;

  if (allowedUserTypes) {
    canAccess = checkAccess({ allowedUserTypes });
  }

  if (typeof policyCheck !== 'undefined') {
    canAccess = policyCheck;
  }

  return <>{canAccess ? children : forbiddenFallback}</>;
};
