import * as React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';

import { useResetPassword } from '../api/resetPassword';

// import { useResetPassword, ResetPasswordDTO } from '../api/resetPassword';
// import { useAuth } from '@/lib/auth';

const schema = z
  .object({
    password: z.string().min(7, 'Required'),
    confirm: z.string().min(7, 'Required'),
  })
  .refine(
    (data) => {
      return data.password === data.confirm;
    },
    {
      message: "Passwords don't match",
      path: ['confirm'],
    }
  );
type ResetPasswordValues = {
  password: string;
  confirm: string;
};

type ResetPasswordFormProps = {
  onSuccess: () => void;
};

export const ResetPasswordForm = ({ onSuccess }: ResetPasswordFormProps) => {
  const resetPassword = useResetPassword();
  const [searchParams] = useSearchParams();
  const temporaryToken = searchParams.get('reset_token');

  return (
    <div>
      <Form<ResetPasswordValues, typeof schema>
        onSubmit={async (values) => {
          await resetPassword.mutateAsync({ ...values, temporary_token: temporaryToken });
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="password"
              label="Password"
              error={formState.errors['password']}
              registration={register('password')}
            />
            <InputField
              type="password"
              label="Confirm Password"
              error={formState.errors['confirm']}
              registration={register('confirm')}
            />

            <div>
              <Button type="submit" className="w-full">
                Submit
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-end">
        <div className="text-sm">
          <Link to="../login" className="font-medium text-blue-600 hover:text-blue-500">
            Log In
          </Link>
        </div>
      </div>
    </div>
  );
};
