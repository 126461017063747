const storagePrefix = 'aldr_';
import Cookies from 'js-cookie';
const storage = {
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string);
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
    Cookies.set('auth_token', token, { domain: '.aldr.co', expires: 30 });
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
    Cookies.remove('auth_token', { domain: '.aldr.co' });
  },
  getValue: (value: string) => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}_${value}`) as string);
  },
  setValue: ({ value, data }: { value: string; data: any }) => {
    window.localStorage.setItem(`${storagePrefix}_${value}`, JSON.stringify(data));
  },
  clearValue: (value: string) => {
    window.localStorage.removeItem(`${storagePrefix}_${value}`);
  },
};

export default storage;
