import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { UserResponse } from '../types';

export type ResetPaswordDTO = {
  password: string;
  temporary_token: string | null;
};

export const resetPassword = (data: ResetPaswordDTO): Promise<UserResponse> => {
  return axios.put('/auth/reset-password/', data);
};

type UseResetPasswordOptions = {
  config?: MutationConfig<typeof resetPassword>;
};

export const useResetPassword = ({ config }: UseResetPasswordOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Password successfully reset',
      });
    },
    ...config,
    mutationFn: resetPassword,
  });
};
