import { Menu, Transition } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { NavLink, Link } from 'react-router-dom';

import logo from '@/assets/logo.svg';
import { useAuth } from '@/lib/auth';
import { useAuthorization, USERTYPES_ENUM } from '@/lib/authorization';

type MainNavigationItem = {
  name: string;
  to: string;
};

const MainNavigation = () => {
  const { checkAccess } = useAuthorization();
  const { user } = useAuth();
  const navigation = [
    checkAccess({ allowedUserTypes: [USERTYPES_ENUM.ISSUER, USERTYPES_ENUM.ADMIN] }) && {
      name: 'Faucets',
      to: './faucets',
    },
    checkAccess({ allowedUserTypes: [USERTYPES_ENUM.ISSUER, USERTYPES_ENUM.ADMIN] }) && {
      name: 'Tokens',
      to: './tokens',
    },
    checkAccess({ allowedUserTypes: [USERTYPES_ENUM.ISSUER, USERTYPES_ENUM.ADMIN] }) && {
      name: 'Wallet',
      to: './wallet',
    },
    checkAccess({ allowedUserTypes: [USERTYPES_ENUM.USER] }) && {
      name: 'Issue Tokens',
      to: './auth/entity',
    },
    checkAccess({
      allowedUserTypes: [USERTYPES_ENUM.USER, USERTYPES_ENUM.ISSUER, USERTYPES_ENUM.ADMIN],
    }) && {
      name: 'Gallery',
      to: '/app/profile',
    },
    !user && {
      name: 'Log In',
      to: '/auth/login',
    },
    !user && {
      name: 'Register',
      to: '/auth/register',
    },
  ].filter(Boolean) as MainNavigationItem[];

  return (
    <>
      {navigation.map((item, index) => (
        <NavLink
          end={index === 0}
          key={item.name}
          to={item.to}
          className="group whitespace-nowrap flex items-center mr-6 py-2 text-sm uppercase text-neutral-700 font-medium hover:text-royal-blue-500 ease-out duration-300"
          activeClassName="border-b-2 border-solid border-neutral-400"
        >
          {item.name}
        </NavLink>
      ))}
    </>
  );
};

type UserNavigationItem = {
  name: string;
  to: string;
  onClick?: () => void;
};

const UserNavigation = () => {
  const { logout, user } = useAuth();
  const userNavigation = [
    {
      name: 'Sign out',
      to: '',
      onClick: () => {
        logout();
      },
    },
  ].filter(Boolean) as UserNavigationItem[];

  return !user ? (
    <></>
  ) : (
    <Menu as="div" className="mr-2 relative">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="max-w-xs border-box hover:bg-neutral-300 transition ease-out duration-300 p-1.5 flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span className="sr-only">Open user menu</span>
              <UserIcon className="h-5 w-5 rounded-full" />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      onClick={item.onClick}
                      to={item.to}
                      className={clsx(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}
                    >
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

const Logo = () => {
  const { user } = useAuth();
  return (
    <Link className="flex items-center text-white" to={user ? '/app/profile' : 'auth/login'}>
      <img className="h-8 w-auto" src={logo} alt="Workflow" />
    </Link>
  );
};

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <div className="h-screen flex overflow-hidden bg-neutral-300">
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
          {!isMobile && (
            <div className="flex-1 px-4 flex justify-start">
              <Logo />
            </div>
          )}
          <div className="flex-1 px-4 flex justify-end">
            <div className="flex items-center md:ml-6">
              <MainNavigation />
              <UserNavigation />
            </div>
          </div>
        </div>
        <main className="flex-1 relative flex flex-col overflow-y-auto focus:outline-none">
          {children}
        </main>
      </div>
    </div>
  );
};
