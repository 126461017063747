import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useAuth } from '@/lib/auth';

import { useCreateEntity } from '../api/createEntity';

const schema = z.object({
  name: z.string().min(1, 'Required'),
  logo: z.instanceof(FileList).transform((val: any) => {
    return val[0] || undefined;
  }),
});

type EntityValues = {
  name: string;
  logo: string;
};

type CreateEntityFormProps = {
  onSuccess: () => void;
};

export const CreateEntityForm = ({ onSuccess }: CreateEntityFormProps) => {
  const { refetchUser } = useAuth();
  const createEntity = useCreateEntity();

  return (
    <div>
      <div className="mb-4">
        <h3 className="mb-2 font-bold text-xl text-neutral-900">Create your Organization</h3>
        <h3 className="text-sm text-neutral-500">
          Create an organization to issue tokens from. This will allow you to create and manage SPL
          Tokens as well as custom Aldr &quot;faucets&quot; to distribute them.{' '}
        </h3>
      </div>
      <Form<EntityValues, typeof schema>
        onSubmit={async (values) => {
          await createEntity.mutateAsync({
            ...values,
          });
          refetchUser();
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="text"
              label="Organization Name"
              error={formState.errors['name']}
              registration={register('name')}
            />
            <InputField
              type="file"
              label="Logo"
              error={formState.errors['logo']}
              registration={register('logo')}
            />
            <div>
              <Button isLoading={createEntity.isLoading} type="submit" className="w-full">
                Create Organization
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
