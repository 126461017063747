import { Location } from 'history';
import { Navigate } from 'react-router-dom';

import { lazyImport } from '@/utils/lazyImport';

const { AuthRoutes } = lazyImport(() => import('@/features/auth'), 'AuthRoutes');

export const publicRoutes = ({ location }: { location: Location }): Array<any> => {
  return [
    {
      path: '/auth/*',
      element: <AuthRoutes />,
    },
    { path: '*', element: <Navigate to="/auth/login" replace state={{ from: location }} /> },
  ];
};
