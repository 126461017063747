import { Location } from 'history';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { lazyImport } from '@/utils/lazyImport';

const { Profile } = lazyImport(() => import('@/features/users'), 'Profile');
const { DistributionRoutes } = lazyImport(
  () => import('@/features/distribution'),
  'DistributionRoutes'
);
const { WalletRoutes } = lazyImport(() => import('@/features/wallets'), 'WalletRoutes');
const { TokenManagementRoutes } = lazyImport(
  () => import('@/features/tokens'),
  'TokenManagementRoutes'
);
const { ProtectedAuthRoutes } = lazyImport(() => import('@/features/auth'), 'ProtectedAuthRoutes');
const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = ({ location }: { location: Location }): Array<any> => {
  return [
    {
      path: '/app',
      element: <App />,
      children: [
        { path: '/profile', element: <Profile /> },
        { path: '/faucets/*', element: <DistributionRoutes /> },
        { path: '/wallet/*', element: <WalletRoutes /> },
        { path: '/tokens/*', element: <TokenManagementRoutes /> },
        { path: '/auth/*', element: <ProtectedAuthRoutes /> },
        { path: '*', element: <Navigate to="./profile" /> },
      ],
    },
    { path: '*', element: <Navigate to="/app/profile" replace state={{ from: location }} /> },
  ];
};
