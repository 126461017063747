import * as React from 'react';
import { Link } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';

import { useRequestResetPassword } from '../api/requestResetPassword';

const schema = z.object({
  email: z.string().nonempty().min(1, 'Required').email(),
});
type RequestResetPasswordValues = {
  email: string;
};

export const RequestResetPasswordForm = () => {
  const requestResetPassword = useRequestResetPassword();

  return (
    <div>
      <Form<RequestResetPasswordValues, typeof schema>
        onSubmit={async (values) => {
          await requestResetPassword.mutateAsync(values);
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label="Email"
              error={formState.errors['email']}
              registration={register('email')}
            />

            <div>
              <Button type="submit" className="w-full">
                Request Password Reset
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-end">
        <div className="text-sm">
          <Link to="../login" className="font-medium text-blue-600 hover:text-blue-500">
            Log In
          </Link>
        </div>
      </div>
    </div>
  );
};
