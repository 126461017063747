import { Navigate } from 'react-router';

import { Landing } from '@/features/misc';
import { useAuth } from '@/lib/auth';
import { lazyImport } from '@/utils/lazyImport';

const { RedemptionRoutes } = lazyImport(() => import('@/features/redemption'), 'RedemptionRoutes');

export const CommonRoutes = () => {
  const auth = useAuth();
  return [
    { path: '/', element: auth.user ? <Navigate to="/app/profle" /> : <Landing /> },
    {
      path: '/redeem/*',
      element: <RedemptionRoutes />,
    },
  ];
};
