import { Navigate, useNavigate } from 'react-router-dom';

import { Authorization, USERTYPES_ENUM } from '@/lib/authorization';

import { CreateEntityForm } from '../components/CreateEntityForm';
import { Layout } from '../components/Layout';

export const CreateEntity = () => {
  const navigate = useNavigate();

  return (
    <Layout hideLogo>
      <Authorization
        forbiddenFallback={<Navigate to="/app/profile" />}
        allowedUserTypes={[USERTYPES_ENUM.USER]}
      >
        <CreateEntityForm onSuccess={() => navigate('/app/profile')} />
      </Authorization>
    </Layout>
  );
};
