import { Route, Routes } from 'react-router-dom';

import { CreateEntity } from './CreateEntity';
import { Login } from './Login';
import { Register } from './Register';
import { RequestResetPassword } from './RequestResetPassword';
import { ResetPassword } from './ResetPassword';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="register" element={<Register />} />
      <Route path="login" element={<Login />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="request-reset-password" element={<RequestResetPassword />} />
    </Routes>
  );
};

export const ProtectedAuthRoutes = () => {
  return (
    <Routes>
      <Route path="entity" element={<CreateEntity />} />
    </Routes>
  );
};
