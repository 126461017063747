import { Layout } from '../components/Layout';
import { RequestResetPasswordForm } from '../components/RequestResetPasswordForm';

export const RequestResetPassword = () => {
  return (
    <Layout title="Reset your password">
      <RequestResetPasswordForm />
    </Layout>
  );
};
