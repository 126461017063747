import { useNavigate, useLocation } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { LoginForm } from '../components/LoginForm';

type LocationProps = {
  state: {
    from: Location;
  };
};

export const Login = () => {
  const navigate = useNavigate();
  const location = useLocation() as unknown as LocationProps;
  const from = location.state?.from;

  return (
    <Layout title="Log in to your account">
      <LoginForm onSuccess={() => (from ? navigate(from) : navigate('/app/profile'))} />
    </Layout>
  );
};
