import clsx from 'clsx';
import * as React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password' | 'number' | 'file';
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  denoteAsOptional?: boolean;
  children?: React.ReactNode;
  registration: Partial<UseFormRegisterReturn>;
};

export const InputField = (props: InputFieldProps) => {
  const {
    type = 'text',
    label,
    className,
    placeholder,
    disabled,
    denoteAsOptional = false,
    registration,
    children,
    error,
  } = props;
  return (
    <FieldWrapper denoteAsOptional={denoteAsOptional} label={label} error={error}>
      <input
        type={type}
        placeholder={placeholder}
        readOnly={disabled}
        className={clsx(
          'appearance-none block w-full flex-grow px-3 py-3 border border-neutral-400 rounded-md shadow-sm placeholder-neutral-500 focus:outline-none sm:text-sm',
          className,
          !disabled && 'focus:ring-blue-500 focus:border-blue-500',
          disabled && 'bg-neutral-300 pointer-events-none'
        )}
        {...registration}
      />
      {children}
    </FieldWrapper>
  );
};
