import { useNavigate } from 'react-router';

import { Button } from '@/components/Elements';
import { Head } from '@/components/Head';
import { MainLayout } from '@/components/Layout';
import { useAuth } from '@/lib/auth';

export const Landing = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleStart = (to: string) => {
    if (user) {
      navigate('/app/profile');
    } else {
      navigate(to);
    }
  };

  return (
    <>
      <MainLayout>
        <Head description="Welcome to Aldr" />
        <div className="bg-neutral-000 h-[100vh] flex items-center">
          <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">Your life, on the blockchain</span>
            </h2>
            <p className="mt-2 max-w-xl text-lg">
              Aldr is the first bridge between physical and digital.
              <br /> Allow your consumers to prove ownership, attendance, and loyalty — and build
              your online presence in a brand new way.
            </p>
            <div className="mt-8 flex justify-center">
              <div className="inline-flex rounded-md shadow">
                <Button
                  onClick={() => handleStart('/auth/login')}
                  startIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                      />
                    </svg>
                  }
                >
                  Log In
                </Button>
              </div>
              <div className="ml-3 inline-flex">
                <Button
                  onClick={() => handleStart('/auth/register')}
                  variant="inverse"
                  startIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                      />
                    </svg>
                  }
                >
                  Register
                </Button>
              </div>
            </div>
            <p className="mt-6 caption-text">
              Have questions? Contact us at <a href="mailto:info@aldr.co">info@aldr.co</a>.
            </p>
          </div>
        </div>
      </MainLayout>
    </>
  );
};
