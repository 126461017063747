import { useNavigate } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { ResetPasswordForm } from '../components/ResetPasswordForm';

export const ResetPassword = () => {
  const navigate = useNavigate();

  return (
    <Layout title="Reset your password">
      <ResetPasswordForm onSuccess={() => navigate('/auth/login/')} />
    </Layout>
  );
};
