import { Switch } from '@headlessui/react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Tooltip } from '@/components/Elements/Tooltip/Tooltip';
import { Form, InputField } from '@/components/Form';
import { AuthCopy } from '@/copy/authCopy';
import { useAuth } from '@/lib/auth';
const schema = z.object({
  email: z.string().nonempty().min(1, 'Required').email(),
  username: z.string().nonempty().min(1, 'Required'),
  password: z.string().nonempty().min(7).max(64),
});
type RegisterValues = {
  username: string;
  email: string;
  password: string;
};

type RegisterFormProps = {
  onSuccess: (toOrg: boolean) => void;
};

const issuerTooltipText = AuthCopy['issuer-tooltip'];

export const RegisterForm = ({ onSuccess }: RegisterFormProps) => {
  const { register, isRegistering } = useAuth();

  const [createEntity, setCreateEntity] = React.useState(false);

  return (
    <div>
      <Form<RegisterValues, typeof schema>
        onSubmit={async (values) => {
          await register(values);
          onSuccess(createEntity);
        }}
        schema={schema}
        options={{
          shouldUnregister: true,
        }}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="text"
              label="Username"
              error={formState.errors['username']}
              registration={register('username')}
            />
            <InputField
              type="email"
              label="Email Address"
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              label="Password"
              error={formState.errors['password']}
              registration={register('password')}
            />
            <Switch.Group>
              <div className="flex items-center">
                <Switch
                  checked={createEntity}
                  onChange={setCreateEntity}
                  key="entity"
                  className={`${
                    createEntity ? 'bg-royal-blue-600' : 'bg-neutral-400'
                  } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  <span
                    className={`${
                      createEntity ? 'translate-x-6' : 'translate-x-1'
                    } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                  />
                </Switch>
                <Switch.Label className="ml-4 mr-2">Issue my own tokens</Switch.Label>
                <Tooltip text={issuerTooltipText} />
              </div>
            </Switch.Group>
            <div>
              <Button isLoading={isRegistering} type="submit" className="w-full">
                Register
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-end">
        <div className="text-sm">
          <Link to="../login" className="font-medium text-blue-600 hover:text-blue-500">
            Log In
          </Link>
        </div>
      </div>
    </div>
  );
};
