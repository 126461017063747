import clsx from 'clsx';
import * as React from 'react';
import { FieldError } from 'react-hook-form';

type FieldWrapperProps = {
  label?: string;
  className?: string;
  children: React.ReactNode;
  error?: FieldError | undefined;
  denoteAsOptional?: boolean;
  description?: string;
};

export type FieldWrapperPassThroughProps = Omit<FieldWrapperProps, 'className' | 'children'>;

export const FieldWrapper = (props: FieldWrapperProps) => {
  const { label, className, error, children, denoteAsOptional } = props;
  return (
    <div>
      <label className={clsx('block text-sm font-medium text-gray-700', className)}>
        {label}{' '}
        {denoteAsOptional && <span className="ml-1 tiny-caption-text italic">Optional</span>}
        <div className="mt-1 flex">{children}</div>
      </label>
      {error?.message && (
        <div role="alert" aria-label={error.message} className="text-xs mt-1 text-danger-500">
          {error.message}
        </div>
      )}
    </div>
  );
};
