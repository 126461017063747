import clsx from 'clsx';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';

const variants = {
  secondary: 'text-neutral-500 hover:text-royal-blue-500',
  danger: 'text-danger-500 hover:text-danger-800',
};

const defaultIcon = (variant: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx('h-6 w-6', variant)}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

export type TooltipProps = React.ButtonHTMLAttributes<HTMLDivElement> & {
  variant?: keyof typeof variants;
  isLoading?: boolean;
  icon?: React.ReactElement;
  text: string;
};

export const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(
  ({ className = '', variant = 'secondary', icon, text, ...props }) => {
    return (
      <div
        className={clsx(
          'flex justify-center items-center transition-all font-medium focus:outline-none max-w-xs',
          variants[variant],
          className
        )}
        data-tip={text}
        {...props}
      >
        {icon || defaultIcon(variants[variant])}
        <ReactTooltip className="max-w-xs" />
      </div>
    );
  }
);

Tooltip.displayName = 'Tooltip';
