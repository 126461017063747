import { useMutation } from 'react-query';

import { OrgEntity } from '@/features/users';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { formatFormData } from '@/utils/format';

export type CreateEntityDTO = {
  name: string;
  logo?: string | File;
};

export const createEntity = (data: CreateEntityDTO): Promise<OrgEntity> => {
  return axios.post('/users/entity/', formatFormData(data));
};

type UseCreateEntityOptions = {
  config?: MutationConfig<typeof createEntity>;
};

export const useCreateEntity = ({ config }: UseCreateEntityOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Organization created successfully',
      });
    },
    ...config,
    mutationFn: createEntity,
  });
};
