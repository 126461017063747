import { useRoutes, useLocation } from 'react-router-dom';

import { useAuth } from '@/lib/auth';

import { CommonRoutes } from './common';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const auth = useAuth();
  const location = useLocation();

  const routes = auth.user ? protectedRoutes({ location }) : publicRoutes({ location });

  const element = useRoutes([...routes, ...CommonRoutes()]);

  return <>{element}</>;
};
