import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { UserResponse } from '../types';

export type RequestResetPasswordDTO = {
  email: string;
};

export const requestResetPassword = (data: RequestResetPasswordDTO): Promise<UserResponse> => {
  return axios.post('/auth/reset-password/', data);
};

type UseRequestResetPasswordOptions = {
  config?: MutationConfig<typeof requestResetPassword>;
};

export const useRequestResetPassword = ({ config }: UseRequestResetPasswordOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Check your email to reset your password',
      });
    },
    ...config,
    mutationFn: requestResetPassword,
  });
};
